
import { combineReducers } from 'redux';

import app from './app';
import galleryImages from './gallery-images';
import favorites from './favorites';
import objectProperties from './object-properties';
import libraryPanel from './library-panel';
import network from './network';
import allocation from './allocation';
import userManagement from './user-management';
import dashboard from './dashboard';
import scheduler from './scheduler';

export default combineReducers({
  ...Object.entries({
    app,
    galleryImages,
    favorites,
    objectProperties,
    libraryPanel,
    network,
    allocation,
    userManagement,
    dashboard,
    scheduler
  }).reduce(
    (acc, [key, createReducer]) => ({
      ...acc,
      [key]: createReducer()
    }),
    {}
  )
});
