import Axios from "axios";
import Auth from '@aws-amplify/auth';
import { BASE_API_URL } from "config";

const instance = Axios.create({
  baseURL: BASE_API_URL,
});

instance.interceptors.request.use(function (config) {
  return Auth.currentSession()
    .then((session) => {
      config.headers.Authorization = session.idToken.jwtToken;
      return Promise.resolve(config);
    })
    .catch(() => {
      Auth.signOut();
      return Promise.resolve(config);
    });
});

export const getRequest = (...params) =>
  instance.get(...params).then((res) => res.data);

export const postRequest = (...params) =>
  instance.post(...params).then((res) => res.data);

export const putRequest = (...params) =>
  instance.put(...params).then((res) => res.data);

export const deleteRequest = (...params) =>
  instance.delete(...params).then((res) => res.data);

export default instance;
