import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";

import AssignmentIcon from "@material-ui/icons/Assignment";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ClockIcon from "components/Icons/SvgIcons/ClockIcon";
import NetworkOutlinedIcon from "components/Icons/SvgIcons/NetworkOutlinedIcon";
import SpannerIcon from "components/Icons/SvgIcons/SpannerIcon";
import PAGES from "utils/links/pages";
import AllocLink from "components/AllocLink";

const useStyles = makeStyles((theme) => ({
  tabs: {
    width: "100%",
  },
  tabRoot: {
    padding: 0,
    maxWidth: "100%",
    minWidth: theme.custom.layout.openedDrawerWidth,
    textDecoration: "none !important", // TODO: proper styling instead of using none
  },
  tabIndicator: {
    display: "none",
  },
  tabWrapper: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  tabLabelIcon: {
    padding: 0,
    minHeight: theme.spacing(8),
  },
  tabIconWrapper: {
    width: theme.custom.layout.closedDrawerWidth,
    height: theme.custom.layout.closedDrawerWidth,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  iconWrapper: {
    width: theme.custom.layout.closedDrawerWidth,
    height: theme.custom.layout.closedDrawerWidth,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  selectedTab: {
    backgroundColor: theme.palette.primary.main,
    transition: `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeIn} 0ms`, // TODO: improve animation
  },
  disabled: {
    display: "none",
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 14,
    left: 0,
    boxShadow: "0px 2px 6px #ccc",
  },
  arrow: {
    color: theme.palette.primary.main,
  },
}));

const getMenus = (iconColor, permissions = {}) => [
  {
    id: 0,
    label: "Dashboard",
    links: [PAGES.DASHBOARD],
    icon: <DashboardIcon style={{ color: iconColor }} />,
  },
  {
    id: 1,
    label: "Networks",
    links: [PAGES.NETWORK],
    icon: <NetworkOutlinedIcon width={24} height={24} color={iconColor} />,
  },
  {
    id: 2,
    label: "Reading Config",
    links: [PAGES.READING_CONFIGURATION],
    icon: <SpannerIcon color={iconColor} />,
  },
  {
    id: 3,
    label: "Monthly Adjustment",
    links: [PAGES.MONTHLY_ADJUSTMENT],
    icon: <AssignmentIcon style={{ color: iconColor }} />,
    hidden: permissions.ADJUSTMENT_VIEW !== true,
  },
  {
    id: 4,
    label: "Scheduler",
    links: [PAGES.SCHEDULER],
    icon: <ClockIcon color={iconColor} />,
  },
  {
    id: 5,
    label: "User Management",
    links: [
      `${PAGES.USER_MANAGEMENT.ROOT}${PAGES.USER_MANAGEMENT.USERS}`,
      `${PAGES.USER_MANAGEMENT.ROOT}${PAGES.USER_MANAGEMENT.GROUPS}`,
    ],
    icon: <SupervisedUserCircleIcon style={{ color: iconColor }} />,
  },
];

const TabIconWrapper = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.tabIconWrapper}>{children}</div>;
};

const TabLabel = ({ children }) => (
  <Typography variant="h5" color="textSecondary" style={{ fontWeight: 700 }}>
    {children}
  </Typography>
);

const DrawerMenu = ({ isOpen, permissions }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [currentMenuIndex, setCurrentMenuIndex] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const menus = getMenus();
    const expectedCurrentMenuIndex = menus.findIndex((menu) => {
      return menu.links && menu.links.includes(history.location.pathname);
    });
    if (expectedCurrentMenuIndex > -1) {
      setCurrentMenuIndex(expectedCurrentMenuIndex);
    }
  }, [history.location.pathname]);

  const menus = getMenus(theme.palette.common.white, permissions);

  const onChangeHandler = (event, selectedMenuIndex) => {
    if (selectedMenuIndex !== currentMenuIndex) {
      setCurrentMenuIndex(selectedMenuIndex);
    }
  };

  return (
    <Tabs
      classes={{ indicator: classes.tabIndicator }}
      orientation="vertical"
      variant="scrollable"
      value={currentMenuIndex}
      onChange={onChangeHandler}
      aria-label="vertical menu bar"
      className={classes.tabs}
    >
      {menus.map((menu) => (
        <Tab
          component={AllocLink}
          href={menu.links[0]}
          key={menu.id}
          id={`vertical-tab-${menu.id}`}
          aria-controls={`vertical-tabpanel-${menu.id}`}
          disabled={menu.hidden}
          classes={{
            root: classes.tabRoot,
            selected: classes.selectedTab,
            wrapper: classes.tabWrapper,
            labelIcon: classes.tabLabelIcon,
            disabled: menu.hidden ? classes.disabled : null,
          }}
          icon={
            <TabIconWrapper>
              {!isOpen && (
                <Tooltip
                  title={menu.label}
                  arrow
                  placement="right"
                  classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                >
                  <div className={classes.iconWrapper}>{menu.icon}</div>
                </Tooltip>
              )}
              {isOpen && menu.icon}
            </TabIconWrapper>
          }
          label={<TabLabel>{menu.label}</TabLabel>}
        />
      ))}
    </Tabs>
  );
};

DrawerMenu.propTypes = {
  permissions: PropTypes.shape({}),
};

DrawerMenu.defaultProps = {
  permissions: {},
};

export default DrawerMenu;
