import format from "date-fns/format";

import omit from "lodash/omit";

import { PRODUCT_TYPES } from "utils/constants/network";

const getProductTypeColor = (theme, productType) => {
  let productTypeColor;
  switch (productType) {
  case PRODUCT_TYPES.GAS:
    productTypeColor = theme.custom.palette.red;
    break;
  case PRODUCT_TYPES.OIL:
    productTypeColor = theme.custom.palette.green;
    break;
  case PRODUCT_TYPES.WATER:
    productTypeColor = theme.custom.palette.blue;
    break;
  default:
    productTypeColor = theme.custom.palette.grey;
    break;
  }

  return productTypeColor;
};

const checkEmptyObject = (targetObject) => {
  if (!targetObject) {
    return false;
  }
  return (
    Object.keys(targetObject).length === 0 &&
    targetObject.constructor === Object
  );
};

const getFormattedDate = (dateString, pattern = "MM-dd-yyyy") => {
  if (!dateString) return "";

  const timeStampInMs = Date.parse(dateString);
  const formattedDate = format(new Date(timeStampInMs), pattern);

  return formattedDate;
};

const insertKeysInArray = (arr, keyName = "key") =>
  arr.map((item) => ({ ...item, [keyName]: Math.random() }));

const removeKeysFromArray = (arr, keyName = ["key"]) =>
  arr.map((item) => omit(item, keyName));

const errorMessage = (err, defaultMessage) => {
  if (err.response && err.response.data && err.response.data.Message) {
    const message = err.response.data.Message.split(/:(.+)/)[1];
    return message;
  }
  return defaultMessage;
};

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export {
  getProductTypeColor,
  checkEmptyObject,
  getFormattedDate,
  insertKeysInArray,
  removeKeysFromArray,
  errorMessage,
  debounce,
};
