
import { createReducer } from 'redux-act';

import * as ALL from '../actions/dashboard';
import { getFormattedDate } from 'utils/helpers/common';

const getDefaultState = () => ({
  filters: {
    Date: getFormattedDate('03-31-2020')
  },
});

export default () =>
  createReducer(
    {
      [ALL.setFilters]: (state, payload) => ({
        ...state,
        filters: {
          ...state.filters,
          ...payload
        },
      }),
    },
    getDefaultState()
  );
