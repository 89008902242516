import { createReducer } from "redux-act";

import generateId from "utils/generateId";

import * as ALL from "../actions/network";

const getDefaultState = () => ({
  isOpen: false,
  isLoading: false,
  isEdit: false,
  isResult: false,
  data: {
    id: generateId(10),
    offsetX: 0,
    offsetY: 0,
    zoom: 100,
    gridSize: 0,
    layers: [
      {
        id: generateId(10),
        type: "diagram-links",
        isSvg: true,
        transformed: true,
        models: {},
      },
      {
        id: generateId(10),
        type: "diagram-nodes",
        isSvg: false,
        transformed: true,
        models: {},
      },
    ],
  },
  filterNetworks: [],
  rawData: {},
  network: {},
  readingConfig: {},
  networkVersionObj: {},
  networkVersionId: null,
  errors: {},
  newNetworkObj: {
    isNew: false,
  },
});

export default () =>
  createReducer(
    {
      [ALL.setOpenNetwork]: (state, payload) => ({
        ...state,
        isOpen: payload,
      }),
      [ALL.getNetworkJson]: (state, payload) => ({
        ...state,
        data: payload.data,
      }),
      [ALL.saveNetworkJson]: (state, payload) => ({
        ...state,
        data: payload,
      }),
      [ALL.setNetworkVersionObj]: (state, payload) => ({
        ...state,
        networkVersionObj: payload,
      }),
      [ALL.setReadingConfig]: (state, payload) => ({
        ...state,
        readingConfig: payload,
      }),
      [ALL.setEditMode]: (state, payload) => ({
        ...state,
        isEdit: payload,
      }),
      [ALL.setResultMode]: (state, payload) => ({
        ...state,
        isResult: payload,
      }),
      [ALL.setIsLoading]: (state, payload) => ({
        ...state,
        isLoading: payload,
      }),
      [ALL.setNetwork]: (state, payload) => ({
        ...state,
        network: payload,
      }),
      [ALL.setNetworkVersionId]: (state, payload) => ({
        ...state,
        networkVersionId: payload,
      }),
      [ALL.setNewNetworkObj]: (state, payload) => ({
        ...state,
        newNetworkObj: payload,
      }),
      [ALL.setNetworkErrors]: (state, payload) => ({
        ...state,
        errors: payload,
      }),
      [ALL.setFilterNetworks]: (state, payload) => ({
        ...state,
        filterNetworks: payload,
      }),
      [ALL.resetNetwork]: (state) => ({
        isOpen: false,
        isLoading: false,
        isEdit: false,
        isResult: false,
        filterNetworks: [],
        data: {
          id: generateId(10),
          offsetX: 0,
          offsetY: 0,
          zoom: 80,
          gridSize: 0,
          layers: [
            {
              id: generateId(10),
              type: "diagram-links",
              isSvg: true,
              transformed: true,
              models: {},
            },
            {
              id: generateId(10),
              type: "diagram-nodes",
              isSvg: false,
              transformed: true,
              models: {},
            },
          ],
        },
        rawData: {},
        network: {},
        networkVersionId: null,
        errors: {},
        newNetworkObj: {
          isNew: false,
        },
        networkVersionObj: {},
        readingConfig: {},
      }),
    },
    getDefaultState()
  );
