import React, { Suspense, lazy } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { withQuicklink } from "quicklink/dist/react/hoc.js";
import CircularProgress from "components/Loading/Circular";

import theme from "styles/theme";
import Layout from "hoc/Layout";
import Loading from "components/Loading";
import PAGES from "utils/links/pages";

const Dashboard = lazy(() =>
  import(/* webpackChunkName: 'dashboard' */ "containers/Dashboard")
);
const Network = lazy(() =>
  import(/* webpackChunkName: 'network' */ "containers/Network")
);
const UserManagement = lazy(() =>
  import(/* webpackChunkName: 'user-management' */ "containers/UserManagement")
);
const ReadingConfiguration = lazy(() =>
  import(
    /* webpackChunkName: 'reading-configuration' */ "containers/ReadingConfiguration"
  )
);

const MonthlyAdjustment = lazy(() =>
  import(
    /* webpackChunkName: 'monthly-adjustment' */ "containers/MonthlyAdjustment"
  )
);

const Scheduler = lazy(() =>
  import(/* webpackChunkName: "scheduler" */ "containers/Scheduler")
);

const App = ({ user, authState, store, permissions, isLoading }) => {
  if (authState === "signedIn") {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {isLoading && (
            <div style={{ height: "100vh" }}>
              <CircularProgress type="static" />
            </div>
          )}
          {!isLoading && (
            <Layout user={user} permissions={permissions}>
              <Suspense fallback={<Loading />}>
                <Switch>
                  <Redirect exact from="/" to={PAGES.DASHBOARD} />
                  <Route
                    path={PAGES.DASHBOARD}
                    component={withQuicklink(Dashboard)}
                  />
                  <Route
                    path={PAGES.NETWORK}
                    component={withQuicklink(Network)}
                  />
                  <Route
                    path={PAGES.USER_MANAGEMENT.ROOT}
                    component={withQuicklink(UserManagement)}
                  />
                  <Route
                    path={PAGES.READING_CONFIGURATION}
                    component={withQuicklink(ReadingConfiguration)}
                  />
                  <Route
                    path={PAGES.SCHEDULER}
                    component={withQuicklink(Scheduler)}
                  />
                  {permissions.ADJUSTMENT_VIEW === true && (
                    <Route
                      path={PAGES.MONTHLY_ADJUSTMENT}
                      component={withQuicklink(MonthlyAdjustment)}
                    />
                  )}
                </Switch>
              </Suspense>
            </Layout>
          )}
        </ThemeProvider>
      </Provider>
    );
  }
  return <div />;
};

export default App;
