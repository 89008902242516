
const BASE_API_URL = process.env.REACT_APP_API_URL;


// e.g. API endpoint
// https://jsonplaceholder.typicode.com/photos?_start=0&_limit=8

export {
  BASE_API_URL
};
