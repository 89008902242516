import { createReducer } from "redux-act";

import * as ALL from "actions/user-management";

const getDefaultState = () => ({
  groups: [],
  activeGroup: null,
});

export default () =>
  createReducer(
    {
      [ALL.getAllUserGroups]: (state, payload) => ({
        ...state,
        groups: payload,
      }),
      [ALL.setActiveGroup]: (state, payload) => ({
        ...state,
        activeGroup: payload,
      }),
    },
    getDefaultState()
  );
