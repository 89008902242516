import { makeStyles } from "@material-ui/core/styles";

const useCommonStyles = makeStyles((theme) => ({
  // TODO: update naming
  boxShadow: {
    boxShadow: `${theme.spacing(
      10 / 8,
      20 / 8,
      32 / 8,
      0
    )} rgba(208, 235, 237, 0.3)`,
  },
  flexContainer: {
    display: "flex",
  },
  columnFlexDirection: {
    flexDirection: "column",
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const useTableStyles = makeStyles((theme) => ({
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  checkbox: {
    color: `${theme.custom.palette.blueGrey} !important`,
  },
}));

// TODO: sort of duplication of CardWrapper
const useCardStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    width: theme.custom.layout.searchPanelWidth,
    borderRadius: theme.spacing(0.5),
  },
  cardHeader: {
    padding: theme.spacing(1.5, 2),
    backgroundColor: theme.palette.background.default,
  },
  cardHeaderTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  noCardContentPadding: {
    padding: "0 !important",
    "&:last-child": {
      paddingBottom: "0 !important",
    },
  },
  cardActions: {
    paddingLeft: `${theme.spacing(2)}px !important`,
    paddingRight: `${theme.spacing(2)}px !important`,
    justifyContent: "space-between",
  },
}));

const useFormulaStyles = makeStyles((theme) => ({
  rect: (props) => ({
    width: props.widthFixed && `${theme.spacing(4.5)}px !important`,
    height: `${theme.spacing(4.5)}px !important`,
    borderRadius: "0 !important",
    minWidth: "0 !important",
    minHeight: "0 !important",
  }),
  border: {
    border: `1px solid ${theme.custom.palette.lightGreen} !important`,
  },
  noBorder: {
    border: "none !important",
  },
  noBorderTop: {
    borderTop: "none !important",
  },
  noBorderBottom: {
    borderBottom: "none !important",
  },
  noBorderLeft: {
    borderLeft: "none !important",
  },
  noBorderRight: {
    borderRight: "none !important",
  },
  input: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
    },
    "& .MuiOutlinedInput-input": {
      height: theme.spacing(4.5),
      padding: theme.spacing(0, 1),
      border: `1px solid ${theme.custom.palette.lightGreen}`,
      textAlign: "center",
      boxSizing: "border-box",
      ...theme.typography.h6,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));

const useReadingConfigStyles = makeStyles((theme) => ({
  nestedGrid: (props) => ({
    display: "grid",
    gridTemplateColumns: `${theme.spacing(props.nestedWidth / 8)}px auto`,
  }),
  secondaryBgSelect: {
    "& .MuiSelect-root": {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.secondary.main,
      "&:focus": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    "& svg": {
      fill: theme.palette.text.secondary,
    },
  },
  liveDisabled: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "unset !important",
    },
    "& .MuiSelect-icon": {
      display: "none",
    },
  },
}));

const useBackdropStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  static: {
    zIndex: theme.zIndex.drawer + 1,
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  absolute: {
    zIndex: theme.zIndex.drawer + 1,
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export {
  useCommonStyles,
  useTableStyles,
  useCardStyles,
  useFormulaStyles,
  useReadingConfigStyles,
  useBackdropStyles,
};
