
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const AllocLink = React.forwardRef(({
  href,
  ...rest
}, ref) => (
  <Link
    ref={ref}
    component={RouterLink}
    to={href}
    {...rest} />
));

export default AllocLink;
