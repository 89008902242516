// import libraries
import React from "react";
import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import { Authenticator, SignIn } from "aws-amplify-react";
import { Provider } from "react-redux";
import App from "./App";
import awsConfig from "./awsConfig";
import { configureStore } from "./store";
import { postRequest, getRequest } from "api";
import { withRouter } from "react-router-dom";

Amplify.configure(awsConfig);

const store = configureStore();

class AppWithAuth extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      user: null,
      authState: "signedOut",
      isLoading: true,
      permissions: {
        ADJUSTMENT_VIEW: false,
      },
    };

    this.updateLoggedInUserInfo = this.updateLoggedInUserInfo.bind(this);
  }

  // componentDidUpdate(prevProps) {
  // NOTE: in order to have access to this information, you will need
  // to wrap this component in the `withRouter` HOC

  // const { user } = this.state;
  // console.log(user, user?.attributes["custom:tenant_id"]);

  // const {
  //   location: { pathname },
  // } = this.props;
  // const previousLocation = prevProps.location.pathname;

  // if (pathname !== previousLocation) {
  //   window.Appcues.page();
  // }

  // if (user) {
  //   window.Appcues.identify(user?.attributes["custom:tenant_id"], {
  //     email: user.attributes.email,
  //     displayName: user.attributes.family_name,
  //   });
  // }
  // }

  async updateLoggedInUserInfo() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.setState({ user });
      postRequest("user/check");
      getRequest("user/check_permission", {
        params: {
          Permission: "ADJUSTMENT_VIEW",
        },
      })
        .then((res) => {
          this.setState({
            permissions: { ADJUSTMENT_VIEW: res.Status },
            isLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
        });
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    const { user } = this.state;
    return (
      <Provider store={store}>
        <Authenticator
          onStateChange={(authState) => {
            this.setState({ authState });
            if (authState === "signedIn") {
              this.updateLoggedInUserInfo();
              console.log(user);
            } else {
              this.setState({ user: null });
            }
          }}
          hideDefault={true}
        >
          <SignIn />
          <App
            store={store}
            user={user}
            authState={this.state.authState}
            permissions={this.state.permissions}
            isLoading={this.state.isLoading}
          />
        </Authenticator>
      </Provider>
    );
  }
}

export default withRouter(AppWithAuth);
