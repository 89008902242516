const PAGES = {
  DASHBOARD: "/dashboard",
  NETWORK: "/network",
  USER_MANAGEMENT: {
    ROOT: "/user-management",
    USERS: "/users",
    GROUPS: "/groups",
  },
  SCHEDULER: "/scheduler",
  READING_CONFIGURATION: "/reading-configuration",
  MONTHLY_ADJUSTMENT: "/monthly-adjustment",
};

export default PAGES;
