
import { createReducer } from 'redux-act';

import * as ALL from '../actions/library-panel';

const getDefaultState = () => ({
  query: '',
});

export default () =>
  createReducer(
    {
      [ALL.setSearchQuery]: (state, payload) => ({
        ...state,
        query: payload.query,
      }),
    },
    getDefaultState()
  );
