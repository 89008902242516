import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Loading from "components/Loading";
import LeftSideDrawer from "components/Navigation/LeftSideDrawer";
import Header from "components/Navigation/Header";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    // TODO: just hack for withQuicklink's wrapper div added to the DOM so it's fragile
    "& > div:nth-child(3)": {
      flexGrow: 1,
      display: "flex",
    },
  },
}));

const Layout = ({ children, loading, user, permissions }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {loading && <Loading fixed />}
      {!loading && (
        <>
          {process.env.REACT_APP_DISABLE_TOPBAR === "true" ? (
            <header />
          ) : (
            <Header user={user} />
          )}
          <LeftSideDrawer permissions={permissions} />
          {children}
        </>
      )}
    </div>
  );
};

export default Layout;
