import { createAction } from "redux-act";

// action creators for reducer
export const setOpenNetwork = createAction();
export const getNetworkJson = createAction();
export const saveNetworkJson = createAction();
export const setEditMode = createAction();
export const setResultMode = createAction();

export const setNetwork = createAction();
export const setNetworkVersionId = createAction();
export const setNetworkVersionObj = createAction();
export const setReadingConfig = createAction();
export const setIsLoading = createAction();
export const setNetworkErrors = createAction();
export const setFilterNetworks = createAction();

export const resetNetwork = createAction();
export const setNewNetworkObj = createAction();
