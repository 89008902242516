
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: props => ({
    margin: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: props.fullHeight ? '100%' : 'auto',
    backgroundColor: props.backdrop ? 'rgba(0, 0, 0, 0.3)' : 'transparent',
    width: props.fullWidth ? '100%' : 'auto',
  }),
  fixedLoader: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    width: '100vw !important',
    height: '100vh !important',
    position: 'fixed',
    margin: '0 !important',
    left: 0,
    top: 0,
    zIndex: theme.zIndex.drawer + 3,
  },
  absoluteLoader: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    width: '100% !important',
    height: '100% !important',
    position: 'absolute',
    margin: '0 !important',
    left: 0,
    top: 0,
    zIndex: theme.zIndex.drawer + 3,
  },
  flashing: {
    position: 'relative',
    width: 8,
    height: 8,
    borderRadius: 5,
    backgroundColor: theme.custom.palette.brightGreen,
    color: theme.custom.palette.brightGreen,
    animation: `$Flash 1s infinite linear alternate`,
    animationDelay: '0.5s',
    '&::before': {
      content: "''",
      display: 'inline-block',
      position: 'absolute',
      top: 0,
      left: -15,
      width: 8,
      height: 8,
      borderRadius: 5,
      backgroundColor: theme.custom.palette.brightGreen,
      color: theme.custom.palette.brightGreen,
      animation: `$Flash 1s infinite alternate`,
      animationDelay: '0s'
    },
    '&::after': {
      content: "''",
      display: 'inline-block',
      position: 'absolute',
      top: 0,
      left: 15,
      width: 8,
      height: 8,
      borderRadius: 5,
      backgroundColor: theme.custom.palette.brightGreen,
      color: theme.custom.palette.brightGreen,
      animation: `$Flash 1s infinite alternate`,
      animationDelay: '1s'
    },
  },
  '@keyframes Flash': {
    '0%': {
      backgroundColor: theme.custom.palette.brightGreen,
    },
    '50%': {
      backgroundColor: '#bdf9f3',
    },
    '100%': {
      backgroundColor: '#bdf9f3',
    }
  }

}));
const Loading = ({ fullHeight = false, backdrop = false, fullWidth, fixed, absolute }) => {
  const classes = useStyles({ fullHeight, fullWidth, fixed, backdrop });
  return (
    <div className={clsx(classes.container, fixed ? classes.fixedLoader : '', absolute ? classes.absoluteLoader : '')}>
      <div className={classes.flashing} />
    </div>
  );
};

export default Loading;
