
import { createReducer } from 'redux-act';

import * as ALL from '../actions/app';

const getDefaultState = () => ({
  isDrawerOpen: true,
});

export default () =>
  createReducer(
    {
      [ALL.setOpenDrawer]: (state, payload) => ({
        ...state,
        isDrawerOpen: payload,
      }),
    },
    getDefaultState()
  );
