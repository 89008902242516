
import { createReducer } from 'redux-act';

import * as ALL from 'actions/scheduler';

const getDefaultState = () => ({
  schedules: [],
  activeSchedule: {},
});

export default () =>
  createReducer(
    {
      [ALL.getAllSchedules]: (state, payload) => ({
        ...state,
        schedules: payload,
      }),
      [ALL.setActiveSchedule]: (state, payload) => ({
        ...state,
        activeSchedule: payload,
      }),
    },
    getDefaultState()
  );
