import React from "react";
import Auth from '@aws-amplify/auth';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { getFormattedDate } from "utils/helpers/common";
import Logo from "components/Logo";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 3,
    backgroundColor: theme.palette.common.white,
    height: theme.custom.layout.mainHeaderHeight,
    margin: 0,
  },
  toolBar: {
    height: theme.custom.layout.mainHeaderHeight,
    paddingLeft: theme.spacing(0),
    justifyContent: "space-between",
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
  },
  heading: {
    fontSize: 18,
    color: theme.palette.primary.main,
    fontWeight: "bold",
    marginLeft: theme.spacing(2),
  },
  menuButton: {
    height: theme.custom.layout.mainHeaderHeight,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 14,
    textTransform: "capitalize",
  },
  date: {
    color: theme.palette.text.primary,
    textAlign: "right",
  },
  accountWrapper: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function Header({ user }) {
  const { username } = user || {};
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    Auth.signOut()
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <div className={classes.logoWrapper}>
          <Logo widthSpan={theme.custom.layout.closedDrawerWidth} />
          <Typography className={classes.heading}>ALLOCATIONS</Typography>
        </div>
        <div className={classes.accountWrapper}>
          {/* TODO: set Admin name etc from api */}
          <div>
            <Typography className={classes.title}>{username}</Typography>
            <Typography className={classes.date}>
              {getFormattedDate(new Date(), "MMM dd, yyyy")}
            </Typography>
          </div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircleIcon
              color={"primary"}
              fontSize="large"
              width={36}
              height={36}
            />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            keepMounted
            elevation={2}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Help</MenuItem>
            <MenuItem onClick={handleClose}>Version</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}
