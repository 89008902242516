const awsConfig = {
  Auth: {
    region: "us-west-2",
    identityPoolRegion: "us-west-2",
    userPoolId: process.env.REACT_APP_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
  },
};

export default awsConfig;
