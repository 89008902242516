
import { createReducer } from 'redux-act';
import add from 'date-fns/add';

import { getFormattedDate } from 'utils/helpers/common';
import * as ALL from '../actions/allocation';

const getDefaultState = () => ({
  product: [],
  disposition: [],
  productFilters: {},
  dispositionFilters: {},
  dateFilters: {
    RunDate: getFormattedDate(add(new Date(), { days: -1})),
  },
});

export default () =>
  createReducer(
    {
      [ALL.getDisposition]: (state, payload) => ({
        ...state,
        disposition: payload,
      }),
      [ALL.getProduct]: (state, payload) => ({
        ...state,
        product: payload,
      }),
      [ALL.setProductFilters]: (state, payload) => ({
        ...state,
        productFilters: payload,
      }),
      [ALL.setDispositionFilters]: (state, payload) => ({
        ...state,
        dispositionFilters: payload,
      }),
      [ALL.setDateFilters]: (state, payload) => ({
        ...state,
        dateFilters: payload,
      }),
    },
    getDefaultState()
  );
