import React, { useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import DrawerMenu from "components/Navigation/LeftSideDrawer/DrawerMenu";
import { useCommonStyles } from "styles/common-use-styles";

import { setOpenDrawer } from "actions/app";

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    border: "none",
    backgroundColor: theme.custom.palette.blueGrey,
    paddingTop: theme.custom.layout.mainHeaderHeight,
  },
  drawerOpen: {
    width: theme.custom.layout.openedDrawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  closeDrawer: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "visible",
    width: theme.custom.layout.closedDrawerWidth,
  },
  menuButton: (props) => ({
    position: "fixed",
    left: props.open
      ? theme.custom.layout.openedDrawerWidth
      : theme.custom.layout.closedDrawerWidth,
    zIndex: theme.zIndex.drawer + 4,
    background: theme.custom.palette.blueGrey,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    width: 32,
    height: 40,
    top: 70,
    alignItems: "center",
    transition: "all 0.25s",
    transitionTimingFunction: "cubic-bezier(0.4, 0, 0.6, 1)",
    display: props.showButton ? "flex" : "none",
  }),
}));

const LeftSideDrawer = ({ open, setOpenDrawer, permissions }) => {
  const commonClasses = useCommonStyles();
  const [showButton, setShowButton] = useState(false);

  const classes = useStyles({ open, showButton });
  const theme = useTheme();

  return (
    <ClickAwayListener onClickAway={() => setOpenDrawer(false)}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.closeDrawer]: !open,
        })}
        classes={{
          paper: clsx(classes.drawerPaper, commonClasses.boxShadow, {
            [classes.drawerOpen]: open,
            [classes.closeDrawer]: !open,
          }),
        }}
        onMouseEnter={(e) => {
          setShowButton(true);
        }}
        onMouseLeave={() => setShowButton(false)}
        open={open}
      >
        <DrawerMenu isOpen={open} permissions={permissions} />
        <div className={classes.menuButton}>
          <IconButton
            edge="start"
            onClick={() => setOpenDrawer(!open)}
            aria-label="open drawer"
            color="primary"
          >
            <MenuIcon style={{ color: theme.palette.common.white }} />
          </IconButton>
        </div>
      </Drawer>
    </ClickAwayListener>
  );
};

const mapStateToProps = (state) => ({
  open: state.app.isDrawerOpen,
});

const mapDispatchToProps = {
  setOpenDrawer: setOpenDrawer,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideDrawer);
