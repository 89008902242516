
import { createReducer } from 'redux-act';

import * as ALL from '../actions/object-properties';

const getDefaultState = () => ({
  isOpen: false,
  isLoading: true,
  data: {},
});

export default () =>
  createReducer(
    {
      [ALL.openObjectPropertiesPanel]: (state, payload) => ({
        ...state,
        isOpen: true,
      }),
      [ALL.updateObjectPropertiesPanel]: (state, payload) => ({
        ...state,
        isLoading: false,
        data: payload,
      }),
      [ALL.closeObjectPropertiesPanel]: (state, payload) => ({
        ...state,
        isOpen: false,
        isLoading: true,
        data: {},
      }),
    },
    getDefaultState()
  );
