import React from "react";
import clsx from "clsx";

import CircularProgress from "@material-ui/core/CircularProgress";
import { useBackdropStyles } from "styles/common-use-styles";

const Ciruclar = ({ type, className, loaderClass }) => {
  const classes = useBackdropStyles();

  switch (type) {
  case "absolute":
    return (
      <div className={clsx(classes.absolute, className)}>
        <CircularProgress className={loaderClass} />
      </div>
    );
  case "static":
    return (
      <div className={clsx(classes.static, className)}>
        <CircularProgress className={loaderClass} />
      </div>
    );
  default:
    return <CircularProgress className={loaderClass} />;
  }
};

export default Ciruclar;
