
import { createAction } from 'redux-act';

// action creators for reducer
export const getDisposition = createAction();
export const getProduct = createAction();

export const setProductFilters = createAction();
export const setDispositionFilters = createAction();
export const setDateFilters = createAction();
