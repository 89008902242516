import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#33d8c7",
      main: "#00cfba",
      dark: "#009082",
      contrastText: "#fff",
    },
    secondary: {
      light: "#7088b7",
      main: "#4d6ba5",
      dark: "#354a73",
      contrastText: "#fff",
    },
    error: {
      light: "#ff3333",
      main: "#ff0000",
      dark: "#b20000",
      contrastText: "#fff",
    },
    background: {
      default: "#f5f6f8",
    },
    text: {
      primary: "#2f2f2f",
      secondary: "#fff",
      disabled: "#848484",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          {
            fontFamily: "Gustanlp",
            fontStyle: "normal",
            fontDisplay: "swap",
            fontWeight: 400,
            src: `
            local('Gustanlp'),
            local('Gustanlp-Regular'),
            url(/assets/fonts/Gustanlp-Medium.woff2) format('woff2')
          `,
          },
        ],
      },
    },
    MuiButton: {
      // TODO: large
      // https://material-ui.com/customization/globals/
      // https://material-ui.com/api/button/
      root: {
        minWidth: 96,
        minHeight: 40,
      },
      sizeSmall: {
        minWidth: 64,
        minHeight: 32,
      },
    },
  },
  typography: {
    fontFamily: "Gustanlp, Arial",
    htmlFontSize: 10,
    button: {
      fontSize: "1.2rem",
      letterSpacing: "-0.33px",
    },
    h3: {
      fontSize: "2.4rem",
      letterSpacing: "-0.67px",
    },
    h4: {
      fontSize: "1.8rem",
      letterSpacing: "-0.5px",
    },
    h5: {
      fontSize: "1.4rem",
      letterSpacing: "-0.39px",
    },
    h6: {
      fontSize: "1.2rem",
      letterSpacing: "-0.33px",
    },
    subtitle1: {
      fontSize: "1rem",
      letterSpacing: "-0.28px",
      fontWeight: 700,
    },
    body1: {
      fontSize: "1rem",
      letterSpacing: "-0.28px",
    },
    subtitle2: {
      fontSize: ".8rem",
      letterSpacing: "-0.22px",
      fontWeight: 700,
    },
    body2: {
      fontSize: ".8rem",
      letterSpacing: "-0.22px",
    },
  },
  custom: {
    // TODO: naming -> professional
    palette: {
      blueGrey: "#424f62",
      lightGrey: "#f0f1f1", // TODO: not used
      lightBlueGrey: "#bed4d8",
      grey: "#4A4A4A", // equipment
      dullGrey: "#bac2c3",
      red: "#F66", // gas
      green: "#6C6", // oil
      blue: "#387DF4", // water
      darkBlue: "#34528b",
      borderGrey: "#d9dadb",
      lightBlue: "#ecf2ff",
      brightGreen: "#00cfba",
      lightGreen: "#d0ebed",
      fadedRed: "#ff8080",
    },
    network: {
      icons: {
        equipment: "#000000",
        meter: "#e13828",
        tank: "#4a90e2",
        well: "#2fa858",
      },
    },
    formula: {
      conditionResult: {
        true: "#66cc66",
        false: "#ff6666",
      },
    },
    layout: {
      // TODO: clean up those unused
      openedDrawerWidth: 272, // TODO: adjust according to design
      closedDrawerWidth: 64,
      upperSubToolbarHeight: 80,
      lowerSubToolbarHeight: 64,
      networkPopperWidth: 320,
      hierarchyPanelWidth: 269,
      mainHeaderHeight:
        process.env.REACT_APP_DISABLE_TOPBAR === "true" ? 0 : 60,
      networkPanelListWidth: 64,
      searchPanelWidth: 360,
      paddingForMainSection: 16,
      userManagementHeadBarHeight: 64, // TODO: expected just manually from HeadBar height
    },
  },
});

export default theme;
