const NODE_TYPE = {
  EQUIPMENT: "Equipment",
  METER: "Meter",
  TANK: "Tank",
  WELL: "Well",
  VIRTUAL: "Virtual", // TODO: dummy virtual type
};

const LINK_TYPE = {
  ADVANCED: "advanced",
};

// TODO: duplicated with styles/theme.js
const COLOR_SET = {
  DEFAULT_COLOR: "rgb(0,192,255)",
  EQUIPMENT: "#000000",
  METER: "#e13828",
  TANK: "#4a90e2",
  WELL: "#2fa858",
};

// TODO: duplicated with styles/theme.js
const PRODUCT_COLOR_SET = {
  Default: "#000000",
  Oil: "#2fa858",
  Water: "#4a90e2",
  Gas: "#e13828",
};

// TODO: duplicated with NODE_TYPE but OBJECT_TYPES makes more sense
const OBJECT_TYPES = {
  EQUIPMENT: "Equipment",
  METER: "Meter",
  TANK: "Tank",
  WELL: "Well",
};

const PRODUCT_TYPES = {
  OIL: "oil",
  GAS: "gas",
  WATER: "water",
};

export {
  NODE_TYPE,
  COLOR_SET,
  LINK_TYPE,
  PRODUCT_COLOR_SET,
  OBJECT_TYPES,
  PRODUCT_TYPES,
};
